.areaTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 100px;
}
.tabs {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 80px;
}
.tabs h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  gap: 20px;
}
.cards div.active {
  border-bottom: 20px solid #ff0558;
}
.cards .card {
  border-bottom: 20px solid #d9d9d9;
  max-width: 250px;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.cards .card h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;

  color: #3d4f59;
}
.tabs .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
}
.tabs .content .para {
  max-width: 590px;
}
.tabs .content .para p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
@media only screen and (max-width: 1024px) {
  .areaTabs {
    padding: 50px 15px;
  }
  .tabs {
    gap: 40px;
  }
  .tabs h1 {
    font-size: 34px;
    line-height: 35px;
    text-align: center;
  }
  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-direction: column; */
    gap: 20px;
  }
  .cards div.active {
    border-bottom: 10px solid #ff0558;
  }
  .cards .card {
    border-bottom: 10px solid #d9d9d9;
    min-width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .cards .card h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: #3d4f59;
  }
  .tabs .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .content img {
    width: 200px;
  }
  .tabs .content .para p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }
  .tabs .content .para {
    width: 300px;
  }
  .labImg img {
    width: 300px;
  }
}
