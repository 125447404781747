.opportunities img {
  width: 100%;
}
.opportunities {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}
.opportunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff4c8;
  padding: 50px 0px;
  gap: 50px;
}
.header {
  max-width: 614px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
  text-align: center;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #3d434d;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 984px;
  align-items: center;
  padding: 70px;
  border-radius: 30px;
  background: #ffffff;
}
.card h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #3d4f59;
}
.card p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #3d4f59;
  max-width: 900px;
}
.card button {
  width: 195.45px;
  height: 47.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .opportunities {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .opportunity {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff4c8;
    padding: 20px 15px;
    gap: 20px;
  }
  .header {
    gap: 15px;
  }
  .header h1 {
    font-size: 32px;
    line-height: 38px;
  }
  .header span {
    font-size: 14px;
    line-height: 24px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    background: #ffffff;
  }
  .card h3 {
    text-align: center;
  }
  .card p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #3d4f59;
    max-width: 900px;
  }
  .card button {
    width: 195.45px;
    height: 47.8px;
    background: #ff0558;
    border-radius: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
}
