.contact {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 100px 0;
}
.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.wrapper form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 630px;
}
form input {
  max-width: 630px;
  height: 60px;
  border: 2px solid #b6b6b6;
  border-radius: 20px;
}
.wrapper form textarea.textArea {
  max-width: 630px;
  height: 200px;
  border: 2px solid #b6b6b6;
  border-radius: 20px;
}
form input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #b6b6b6;
  padding-left: 10px;
  padding-top: 20px;
}
form input[type="text"] {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding-left: 10px;
}
form textarea.textArea::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #b6b6b6;
  padding-left: 10px;
  /* padding-top: 20px; */
}
form textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding-left: 10px;
  padding-top: 20px;
}
form button {
  width: 234.45px;
  height: 55.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: none;
}
.location {
  position: relative;
  text-align: left;
}
.location h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  width: 250px;
  color: #ff0558;
}
.location span {
  width: 250px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
@media only screen and (max-width: 1024px) {
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;
  }
  .wrapper form {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* gap: 20px; */
  }
  form button {
    width: 204.45px;
    height: 45.8px;
    background: #ff0558;
    border-radius: 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    border: none;
  }
  form input {
  }
  .wrapper form textarea.textArea {
  }
  .location img {
    max-width: 300px;
  }
}
