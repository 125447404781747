.career {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 100px 100px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 614px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
  text-align: center;
}
.cards {
  display: flex;
  align-items: center;
  max-width: 984px;
  flex-direction: column;
}
.card {
  padding: 50px 100px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-evenly;
  border-bottom: 15px solid #d0d0d0;
}
.card .cardContent {
  display: flex;
  gap: 25px;
  flex-direction: column;
  max-width: 614px;
}
.cardContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #3d4f59;
}
.cardContent span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
.card img {
  width: 180px;
  height: 180px;
}

@media only screen and (max-width: 1024px) {
  .career {
    gap: 50px;
    padding: 10px 15px;
  }
  .header {
    gap: 10px;
    margin-top: 20px;
  }
  .header h3 {
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    color: #ff0558;
  }
  .header h1 {
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 34px;
    line-height: 40px;
    color: #3d4f59;
  }
  .header span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #3d434d;
    text-align: center;
  }
  .cards {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .card {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    border-bottom: 15px solid #d0d0d0;
  }
  .card .cardContent {
    display: flex;
    gap: 20px;
    flex-direction: column;
    max-width: 614px;
  }
  .cardContent h1 {
    font-size: 25px;
    text-align: center;
    line-height: 30px;
  }
  .cardContent span {
    font-size: 14px;
    text-align: center;
    line-height: 25px;
  }
  .card img {
    width: 160px;
    height: 160px;
  }
}
