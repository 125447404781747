.competences-section {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-bottom: 100px;
  width: 100%;
}
.competences-header {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 670px;
  align-items: center;
  text-align: center;
}
.competences-header h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ff0558;
}
.competences-header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #3d4f59;
}
.competences-header p {
  max-width: 630px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}
.swiper-seciton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.swiper-seciton .content {
  background-image: url("/public/assets/images/div_after.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-seciton .content img {
  max-width: 155px;
  max-height: 155px;
}

.competences-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}
.competences-section .competences-footer button {
  width: 210.38px;
  height: 44.8px;
  background: #ff0558;
  border-radius: 22px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
  cursor: pointer;
}

.competences-section .competences-footer h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;
}
