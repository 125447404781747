.bannerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  max-width: 980px;
}
.bannerContent h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}
.bannerContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .bannerContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 0px 10px;
    max-width: 980px;
  }
  .bannerContent h3 {
    font-size: 18px;
  }
  .bannerContent h1 {
    font-size: 35px;
    line-height: 40px;
  }
}
