.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 100px 100px;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  max-width: 493px;
}
.card img {
  border-radius: 40px;
}
.card h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #3d4f59;
}
.card span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
.card p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
@media only screen and (max-width: 1024px) {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    padding: 10px 10px;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .header h3 {
    font-size: 18px;
    line-height: 20px;
  }
  .header h1 {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }

  .cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }
  .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }
  .card img {
    border-radius: 40px;
    min-width: 350px;
    width: 100%;
  }
  .card h1 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .card span {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .card p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: #000000;
  }
}
