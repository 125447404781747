.bannerContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 405px;
  padding: 100px 200px;
}
.bannerContent h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.bannerContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.bannerContent span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
@media only screen and (max-width: 1024px) {
  .bannerContent {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 15px;
    gap: 10px;
  }
  .bannerContent h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #ff0558;
    text-align: center;
  }
  .bannerContent h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 35px;
    color: #3d4f59;
    text-align: center;
  }
  .bannerContent span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #3d434d;
    text-align: center;
  }
}
