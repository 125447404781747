.footer-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 100px;
  background: linear-gradient(180deg, #fbf8eb 0%, #ffe992 100%);
}
.footer-section .content {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer-section .content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #ff0558;
}
.footer-section .content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
}
.footer-section .content button {
  width: 182.45px;
  height: 44.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.footer-section .picture img {
  width: 279px;
  height: 329px;
}
.footer-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 100px;
  border-bottom: 5px solid #ff0558;
}
.footer-social span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.footer-social .center {
  display: flex;
  gap: 10px;
}
.footer-social .center span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.footer-social .icons {
  display: flex;
  gap: 20px;
}
.footer-social .icons img {
  width: 25px;
  cursor: pointer;
}
