.career {
  background: url("/public/assets/images/person1.png"),
    linear-gradient(180deg, #fff7da 0%, #ffe990 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  height: 600px;
  border-radius: 0px 0px 30px 30px;
}
@media only screen and (max-width: 1024px) {
  .career {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 600px;
  }
}
