.global-section {
  background: #ffe991;
  height: 700px;
  background-image: url("/public//assets/images/Group\ 6.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.global-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 544px;
  padding-top: 150px;
  padding-left: 200px;
}
.global-content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #ff0558;
  text-transform: uppercase;
}

.global-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
}

.global-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}
.global-content button {
  width: 210.38px;
  height: 44.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .global-section {
    background: #ffe991;
    height: 500px;
    background-image: url("/public//assets/images/Group\ 6.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .global-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 284px;
    padding-top: 15px;
    padding-left: 20px;
    margin-top: 10px;
    text-align: center;
  }
  .global-content h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #ff0558;
    text-transform: uppercase;
  }

  .global-content h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
  }

  .global-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }

  .global-content button {
    margin-top: 10px;
    width: 150.38px;
    height: 34.8px;
    background: #ff0558;
    border-radius: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: white;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .global-section {
    background: #ffe991;
    height: 500px;
    background-image: url("/public//assets/images/Group\ 6.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    padding-top: 20px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .global-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 350px;
    padding-top: 35px;
    padding-left: 60px;
    text-align: left;
  }
  .global-content h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ff0558;
    text-transform: uppercase;
  }

  .global-content h1 {
    font-weight: 700;
    font-size: 34.5789px;
    line-height: 40px;
  }

  .global-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }

  .global-content button {
    margin-top: 15px;
    width: 150.38px;
    height: 34.8px;
    background: #ff0558;
    border-radius: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: white;
  }
}
