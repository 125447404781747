.businessDetails {
  padding: 0 100px;
}
.businessContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  /* margin-top: 100px; */
  padding: 80px 80px 80px 80px;
}
.businessContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.businessContent span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
  text-align: center;
}
.businessContent .head {
  gap: 30px;
  display: flex;
  align-items: center;
  max-width: 690px;
  flex-direction: column;
}

.imageSection {
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.bottleImage img {
  width: 244px;
}
.shape {
  position: relative;
  text-align: center;
}
.shape h1 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
  color: #3d4f59;
}

.shape p {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
@media only screen and (max-width: 1024px) {
  .businessDetails {
    padding: 20px 15px;
  }
  .businessContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0px;
  }
  .businessContent h1 {
    text-align: center;
    font-size: 34px;
    line-height: 40px;
  }
  .businessContent span {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .businessContent .head {
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .shape img {
    width: 160px;
  }
  .imageSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }
  .bottleImage img {
    width: 160px;
  }
  .shape {
    position: relative;
    text-align: center;
  }

  .shape h1 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    color: #3d4f59;
  }
  .shape p {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
