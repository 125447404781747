.companyProfile {
  padding: 100px 100px;
  background: #fff4c8;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.content {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  max-width: 614px;
}
.content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
  text-align: center;
}
.content span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #3d434d;
}
.content img {
  width: 300px;
}
@media only screen and (max-width: 1024px) {
  .companyProfile {
    padding: 50px 15px;
  }
  .content {
    gap: 20px;
  }
  .content h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #ff0558;
  }
  .content h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .content span {
    font-size: 14px;
    line-height: 25px;
  }
  .content img {
    width: 200px;
  }
}
