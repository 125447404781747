.policy {
  padding: 100px 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  background: #fff4c8;
}
.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 614px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  text-align: center;
  color: #3d4f59;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #3d434d;
}
.images {
  display: flex;
  gap: 100px;
  flex-direction: column;
}
.images img {
  max-width: 984px;
}
@media only screen and (max-width: 1024px) {
  .policy {
    padding: 30px 15px;
    gap: 30px;
  }
  .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 614px;
  }
  .header h3 {
    font-size: 18px;
  }
  .header h1 {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }
  .header span {
    font-size: 14px;
    line-height: 25px;
  }
  .images {
    gap: 50px;
  }
  .images img {
    width: 100%;
  }
}
