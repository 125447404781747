.banner {
  background: url("/public/assets/images/newsBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
  display: flex;
  justify-content: flex-end;
  border-radius: 0px 0px 30px 30px;
  flex-direction: column;
}
.bannerContent {
  border-radius: 0px 0px 30px 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .banner {
    background: url("/public/assets/images/newsBanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 0px 30px 30px;
    flex-direction: column;
  }
  .bannerContent {
    height: 400px;
  }
}
