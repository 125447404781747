header {
  display: flex;
  align-items: center;
  height: 150px;
  gap: 30px;
  padding: 0 20px;
}

header a img {
  margin-left: 10rem;
  width: 140px;
}

nav a {
  margin: 0 1.5rem;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 115.5%;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
}
nav a:after {
  content: "";
  position: absolute;
  background-color: #ff0558;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}
nav a:hover:after {
  width: 100%;
}
header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.active {
  color: #ff0558;
}
.active:after {
  content: "";
  position: absolute;
  background-color: #ff0558;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: -10px;
  color: #ff0558;
}

@media only screen and (max-width: 1024px) {
  header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background-color: #f4f3f3;

    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    position: relative;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: linear-gradient(180deg, #fff7da 0%, #ffe990 100%);
    transition: 0.5s;
    transform: translateX(-100vh);
    z-index: 1;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.35);
  }

  header a img {
    margin: 0;
    width: 77px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #ffffff;
  }

  nav a {
    font-size: 0.8rem;
    color: #3d4f59;
    margin: 0 0.8rem;
  }
  nav a:hover {
    font-size: 0.8rem;
    color: #3d4f59;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  header {
    display: flex;
    align-items: center;
    height: 150px;
    gap: 30px;
    padding: 0 20px;
  }

  header a img {
    margin-left: 5rem;
    width: 140px;
  }

  nav a {
    margin: 0 1.5rem;
    text-decoration: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 115.5%;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
  }
}
