.details {
  padding: 100px 250px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #ff0558;
}
.content span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
.cards {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.cards .first {
  display: flex;
  flex-direction: column;
}
.cards .first div {
  padding: 30px 0px;
  max-width: 450px;
  border-bottom: 15px solid #3d4f59;
}
.cards .last div {
  padding: 30px 0px;
  max-width: 450px;
  border-bottom: 15px solid #3d4f59;
}
.cards h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.cards h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #3d4f59;
}
.content img {
  margin-top: 100px;
  max-width: 984px;
}
@media only screen and (max-width: 1024px) {
  .details {
    padding: 30px 15px;
  }
  .content {
    gap: 20px;
  }
  .content h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .content span {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .cards .first {
    display: flex;
    flex-direction: column;
  }
  .cards .first div {
    padding: 30px 0px;
    width: 300px;
    border-bottom: 15px solid #3d4f59;
  }
  .cards .last div {
    padding: 30px 0px;
    width: 300px;
    border-bottom: 15px solid #3d4f59;
  }
  .cards h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: #3d4f59;
  }
  .cards h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #3d4f59;
  }
  .content img {
    margin-top: 50px;
    width: 100%;
  }
}
