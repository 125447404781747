.ourBusiness {
  height: 1000px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ourBusiness::before {
  content: "";
  background-image: url("/public/assets/logo/logo.svg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}
.business {
  position: relative;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 720px;
  align-items: center;
}
.business h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.business h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
  text-align: center;
}
.business span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
@media only screen and (max-width: 1024px) {
  .ourBusiness {
    height: 700px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    margin-top: 20px;
  }
  .ourBusiness::before {
    content: "";
    background-image: url("/public/assets/logo/logo.svg");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  }
  .business {
    /* position: relative; */
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 720px;
    align-items: center;
  }
  .business h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #ff0558;
  }
  .business h1 {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }
  .business span {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .business img {
    width: 300px;
  }
}
