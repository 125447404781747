.certification {
  padding: 100px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: 614px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #3d434d;
}
.status {
  padding: 20px 0;
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  gap: 20px;
}
.status div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.status img {
  width: 40px;
}
.status span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
/*  */
.certificates {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.certificates h4 {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #3d4f59;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.first {
  display: flex;
  gap: 20px;
}
.first .card {
  box-sizing: border-box;
  width: 310px;
  height: 250px;
  border: 1px solid #3d434d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 0;
  gap: 20px;
}
.card img {
  width: 200px;
}
.card h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #ff0558;
}
.last {
  display: flex;
  gap: 20px;
}
.last .card {
  box-sizing: border-box;
  width: 310px;
  height: 340px;
  border-radius: 20px;
  border: 1px solid #3d434d;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 0;
  text-align: center;
  gap: 20px;
}
@media only screen and (max-width: 1024px) {
  .certification {
    padding: 50px 15px;
    gap: 40px;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 614px;
  }
  .header h3 {
    font-size: 18px;
  }
  .header h1 {
    text-align: center;
    font-size: 34px;
    line-height: 40px;
  }
  .header span {
    font-size: 14px;
    line-height: 25px;
  }

  .status div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .status img {
    width: 30px;
  }
  .status span {
    font-size: 14px;
    line-height: 25px;
  }
  /*  */
  .certificates {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .certificates h4 {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #3d4f59;
    text-align: center;
  }
  .cards {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .first {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .first .card {
    box-sizing: border-box;
    width: 300px;
    height: 240px;
    border: 1px solid #3d434d;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 0;
    gap: 20px;
  }
  .card img {
    width: 150px;
  }
  .card h3 {
    font-size: 20px;
  }
  .last {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .last .card {
    box-sizing: border-box;
    width: 300px;
    height: 310px;
    border-radius: 20px;
    border: 1px solid #3d434d;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 0;
    text-align: center;
    gap: 20px;
  }
}
