.download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background: #ffffff;
  border-radius: 30px;
}
.content {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}
.content img {
  width: 46px;
}
.content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #3d4f59;
}
.content button {
  width: 224.45px;
  height: 47.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .download {
    padding: 30px 15px;
  }
  .content {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column;
  }
  .download .content img {
    width: 35px;
  }
  .download .content h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #3d4f59;
  }
  .content button {
    width: 200.45px;
    height: 42.8px;
    background: #ff0558;
    border-radius: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
}
