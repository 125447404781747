.main {
  background: url("/public/assets/images/forest.png"), rgba(255, 0, 85, 0.5);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: top right;
  height: 2000px;
  padding: 100px 200px;
}
.header {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 404px;
  margin-top: 100px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  /* or 110% */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* or 170% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.details {
  margin-top: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}
.content {
  align-items: center;
  display: flex;
  max-width: 900px;
  gap: 30px;
}
.content img {
  width: 40px;
}
.content div {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 833px;
}
.content div p {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}
.download {
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .main {
    background: rgba(255, 0, 85, 0.5);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: top right;
    height: inherit;
    padding: 50px 15px;
  }
  .header {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
  }
  .header h3 {
    font-size: 19px;
    line-height: 20px;
  }
  .header h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .header span {
    font-size: 14px;
    line-height: 25px;
  }

  .details {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .content {
    align-items: center;
    display: flex;
    gap: 30px;
  }
  .content img {
    display: none;
  }
  .content div {
    gap: 40px;
  }
  .content div p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .download {
    margin-top: 50px;
  }
}
