.discover {
  display: flex;
  align-items: center;
  justify-content: center;
}
.discoverContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 690px;
  gap: 50px;
}
.discoverContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
  text-align: center;
}
.discoverContent span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
  text-align: center;
}
.discoverContent button {
  width: 210.38px;
  height: 44.8px;
  background: #ff0558;
  border-radius: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  color: #ffffff;
}
@media only screen and (max-width: 1024px) {
  .discover {
    padding: 10px 15px;
  }
  .discoverContent {
    gap: 20px;
  }
  .discoverContent h1 {
    font-size: 34px;
    line-height: 35px;
    text-align: center;
  }
  .discoverContent span {
    font-size: 14px;
    line-height: 25px;
    color: #3d434d;
    text-align: center;
  }
  .discoverContent button {
    width: 200.38px;
    height: 42.8px;
  }
}
