.homeSection {
  margin: 0 20px;
  background: linear-gradient(180deg, #fff7da 0%, #ffe990 100%);
  border-radius: 0px 0px 30px 30px;
}
@media only screen and (max-width: 1024px) {
  .homeSection {
    margin: 0;
  }
}
