.banner {
  max-width: 380px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-left: 17%;
  padding: 80px 0px;
}
.banner-head h3 {
  font-weight: 500;
  font-size: 54.4px;
  line-height: 64px;
}
.banner-head h2 {
  font-weight: 700;
  font-size: 54.4px;
  line-height: 64px;
}
.banner-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}
.banner-btn button {
  padding: 14px 46.375px 13.7969px 47px;
  margin-top: 20px;
  width: 210.38px;
  height: 44.8px;
  border: none;
  background: #ff0558;
  border-radius: 22px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}
