.productRange {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 100px 100px;
}
.productRange .header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 591px;
}
.header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ff0558;
}
.header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54.4px;
  line-height: 60px;
  color: #3d4f59;
}
.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
  text-align: center;
}

.cards {
  max-width: 1000px;
  padding: 50px 50px;
}
.cards .card {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 444px;
  gap: 20px;
}
.cards .card h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  max-width: 380px;

  color: #3d4f59;
}
.cards .card span {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3d434d;
}
@media only screen and (max-width: 1024px) {
  .productRange {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 10px 20px;
  }
  .productRange .header {
    text-align: center;
  }
  .header h3 {
    font-size: 18px;
  }
  .header h1 {
    font-size: 35px;
    line-height: 35px;
  }
  .header span {
    font-size: 14px;
  }

  .cards {
    padding: 0px;
  }
  .cards .card {
    display: flex;
    align-items: center;
    flex-direction: column;

    max-width: 444px;
    gap: 20px;
    padding: 0px 10px;
  }
  .cards .card h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .cards .card img {
    width: 250px;
  }
  .cards .card span {
    font-size: 14px;
    line-height: 25px;
    min-width: 300px;
  }
}
