.business-section {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-bottom: 200px;
}
.business-header {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 670px;
  align-items: center;
  text-align: center;
}
.business-header h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ff0558;
}
.business-header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #3d4f59;
}
.business-header p {
  max-width: 630px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}
.business-card {
  margin-top: 50px;
  display: flex;
  gap: 30px;
}
.business-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 600px;
  gap: 20px;
}

.business-cards .image {
  background: url("/public/assets/images/div_after.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.business-cards .image img {
  height: 330px;
  margin-bottom: 30px;
}
.business-cards .button button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
  width: 210.38px;
  height: 44.8px;
  background: #ff0558;
  border-radius: 22px;
  color: #fff;
  cursor: pointer;
}
.business-cards .content {
  padding-top: 20px;
  width: 271px;
  height: 240px;
}
.business-cards .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .business-card {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  .business-cards .content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .business-cards .image {
    background: url("/public/assets/images/div_after.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .business-cards .image img {
    height: 280px;
  }
  .business-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 450px;
    gap: 20px;
  }
}
